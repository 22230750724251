// @utility
import { cleatStorage, getStorage, setStorage } from "@utility/app/storage";

// Component
import { Grid } from '@mui/material';
import ImageShow from "views/components/ImageShow";

// Icon
import RefreshIcon from '@mui/icons-material/Refresh';

export default function NoData() {
    window.scrollTo({
        top: 0,
        left: 0
    });

    const reloadWeb = () => {
        let market = getStorage("market");
        let agent_contact = getStorage("agent_contact");
        let agent_logo = getStorage("agent_logo");
        cleatStorage();
        setStorage("market", market, 30);
        setStorage("agent_contact", agent_contact);
        setStorage("agent_logo", agent_logo);

        window.location.href = "/";
    };

    return (
        <>
            <ImageShow className="imgError" src="/images/error/no-data.webp" alt="สล็อตออนไลน์"></ImageShow>

            <br /> <br />
            <div className="center">
                <div className="btnErrorReload positionRelative displayInlineBlock textBoldHight cursorPointer" onClick={reloadWeb}>
                    <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <RefreshIcon></RefreshIcon>
                        </Grid>
                        <Grid item>
                            รีโหลดเว็บ
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}