// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_LOTTERY_LIST = "app/lottery/SET_LOTTERY_LIST";

function loadLotteryList(lottery_code = "") {
    return dispatch => {
        apiServices({"lottery_code": lottery_code}, "post", `/general/lottery/list`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: SET_LOTTERY_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_LOTTERY_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

export {
    SET_LOTTERY_LIST,
    loadLotteryList
}