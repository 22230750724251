// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_DATA_ORDER_LIST = 'app/lottery/SET_DATA_ORDER_LIST';
const SET_RESULT_NUMBER = 'app/lottery/SET_RESULT_NUMBER';

function loadOrderList(myJson) {
    return dispatch => {
        apiServices(myJson, "post", "/customer/lottery/ticket", "application/json").then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_ORDER_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_ORDER_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function loadResultNumber(myJson) {
    return dispatch => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));

        dispatch({
            type: SET_RESULT_NUMBER,
            payload: {
                items: []
            }
        });

        apiServices(myJson, "post", "/customer/lottery/ticket/number", "application/json").then((data) => {
            const statusCode = data.code;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;
                
                dispatch({
                    type: SET_RESULT_NUMBER,
                    payload: {
                        items: result
                    }
                });
            }
            else {
                dispatch({
                    type: SET_RESULT_NUMBER,
                    payload: {
                        items: null
                    }
                });
            }
        });
    }
}

export {
    loadOrderList,
    loadResultNumber,
    SET_DATA_ORDER_LIST,
    SET_RESULT_NUMBER
}