import { useEffect, forwardRef, useState } from 'react';

// Component
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';

// Redux & Action
import { useDispatch } from 'react-redux';
import { setSnackbarData } from "@core/redux/actions/app/snackbar";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarApp({ snackbarData }) {
    const { statusShow, statusAlert, titleAlert, textAlert } = snackbarData;
    const dispatch = useDispatch();
    const [state] = useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = useState(statusShow);
    const { vertical, horizontal } = state;

    const closeContent = () => {
        dispatch(setSnackbarData({
            statusShow: false,
            statusAlert: "",
            titleAlert: "",
            textAlert: ""
        }));
        setOpen(false);
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        closeContent();
    };

    useEffect(() => {
        let timer1 = setTimeout(() => {
            closeContent();
        }, 6000);

        clearTimeout(timer1);
    }, [snackbarData]);

    return (
        <div style={{ zIndex: "9999 !important" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} style={{ textAlign: "left", fontSize: "16px" }}>
                <Alert severity={statusAlert} onClose={handleClose}>
                    <AlertTitle style={{ fontSize: "18px" }}>
                        <span className="kanit">
                            {titleAlert}
                        </span>
                    </AlertTitle>
                    <span className="kanit">
                        {textAlert}
                    </span>
                </Alert>
            </Snackbar>
        </div>
    );
}