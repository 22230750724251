import { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router';

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { openAuthenApp } from "@core/redux/actions/auth/index";
import { setHomeMenuType } from "@core/redux/actions/home/index";
import { setDataDemo } from "@core/redux/actions/app/demo";
import { setSelectCamp } from "@core/redux/actions/camp/index";
import { setGameDemo } from "@core/redux/actions/game/index";

// JWT & Auth
import { isUserLoggedIn } from "@core/auth/utils";

const GotoPage = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);

    useImperativeHandle(ref, () => ({
        gotoPage(page, action = null) {
            if (action === "login") {
                if (isUserLoggedIn()) {
                    history(page);
                }
                else {
                    let myObj = {
                        tab: "login",
                        show: true
                    };

                    dispatch(openAuthenApp(myObj));
                }
            }
            else {
                history(page);
            }
        },
        gotoCampWithImage(item, typeLink) {
            let page = item.image_link;
            let typeAction = typeLink === null ? item.image_link_type : typeLink;

            if (typeAction === 3) {
                let menuList = dataSetting.menu_camp;
                let dataObj = {};

                let index = menuList.findIndex(item => item.code === page);
                dataObj = menuList[index];

                if (dataObj !== undefined && dataObj !== null) {
                    dispatch(setHomeMenuType({
                        id: dataObj.id,
                        renderPage: dataObj.code,
                        pageTitle: dataObj.title,
                        pageName: dataObj.name,
                        game_camp_image: dataObj.game_camp_image,
                        gameImage: dataObj.image,
                        column: {
                            desktop: dataObj.column_desktop,
                            mobile: dataObj.column_mobile
                        },
                        typeGame: dataObj.game_camp_type
                    }));

                    history("/");
                }
            }
            else if (typeAction === 1) {
                window.location.href = page;
            }
            else {
                if (isUserLoggedIn()) {
                    history(page);
                }
                else {
                    let myObj = {
                        tab: "login",
                        show: true
                    };

                    dispatch(openAuthenApp(myObj));
                }
            }
        },
        gotoPlayGame(code, codeGame = null) {
            let menuArr = dataSetting.menu_camp;
            let index = menuArr.findIndex(item => item.code === code);
            let dataObj = menuArr[index];

            if (dataObj !== undefined && dataObj !== null) {
                dispatch(setHomeMenuType({
                    id: dataObj.id,
                    renderPage: dataObj.code,
                    pageTitle: dataObj.title,
                    pageName: dataObj.name,
                    gameCampType: dataObj.game_camp_type,
                    gameCampImage: dataObj.game_camp_image,
                    gameImage: dataObj.image,
                    column: {
                        desktop: dataObj.column_desktop,
                        mobile: dataObj.column_mobile
                    },
                    typeGame: dataObj.game_camp_type
                }));
            }

            if (codeGame !== null) {
                dispatch(setSelectCamp(codeGame));
            }

            history(`/game/${dataObj.code}`);
        },
        openGame(typeAction, item) {
            if (typeAction === "playGame") {
                dispatch(setDataDemo(true));
                dispatch(setGameDemo(item));
            }
        },
        goBack() {
            history(-1);
        }
    }));

    return null;
});

export default GotoPage;