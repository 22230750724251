// Component
import Button from '@mui/material/Button';

// Icon
import RuleIcon from '@mui/icons-material/Rule';

export default function RuleButton({ setShowTabSelect }) {

    const gotoRule = () => {
        if(setShowTabSelect !== undefined) {
            setShowTabSelect(false);
        }
    };

    return (
        <Button variant="outlined" onClick={() => gotoRule()} className="ruleBtn" size="small" startIcon={<RuleIcon></RuleIcon>}>
        กติกา
    </Button>
    );
}