import {
    SET_STATUS_MENU_FOOTER
} from '../../actions/app/menuFooter';

const initialState = {
    show: true
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_STATUS_MENU_FOOTER:
            return { ...state, show: action.payload.items };
        default:
            return state;
    }
}