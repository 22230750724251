import {
    LOAD_LOTTERY_INFO,
    SET_LOTTERY_CODE
} from './../../actions/lottery/info';

const initialState = {
    result: [],
    code: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_LOTTERY_INFO:
            return { ...state, result: action.payload.items };
        case SET_LOTTERY_CODE:
            return { ...state, code: action.payload.items };
        default:
            return state;
    }
}