// Component
import Grid from '@mui/material/Grid';
import ModalAlert from "./../components/Alert/ModalAlert";

const ConfirmWarning = ({ text, close }) => {
    const onShow = () => {
        if(close) {
            window.close();
        }
    };

    return (
        <ModalAlert show={true} closeBtn={false}>
            <div className="boxTitle center"  >
                แจ้งเตือน
            </div>

            <Grid container justifyContent="center">
                <Grid item>
                    <div className="svg-box">
                        <svg className="circular yellow-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                        </svg>
                        <svg className="alert-sign yellow-stroke">
                            <g transform="matrix(1,0,0,1,-615.516,-257.346)">
                                <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                                    <path className="line" d="M634.087,300.805L673.361,261.53" fill="none" />
                                </g>
                                <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                                    <circle className="dot" cx="621.52" cy="316.126" r="1.318" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </Grid>
            </Grid>

            <div className="contentMarginTop center">
                <div className="confirmTitle" >
                    {text}
                </div>
            </div>

            <div className="boxMarginTop">
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12}>
                        <div className="center">
                            <button className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight" onClick={() => onShow()}  style={{ color: "black", background: "linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%)" }}>
                                ตกลง
                            </button>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </ModalAlert>
    );
}

export default ConfirmWarning;