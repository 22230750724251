// Component
import Grid from '@mui/material/Grid';
import BackButton from "views/components/BackButton";
import SearchButton from "views/components/SearchButton";
import RuleButton from 'views/templates/lotto711/pages/lottery/component/RuleButton';

export default function BackPage({ namePage, statusSearch, backPage, hideBackButton, haveRule, setShowTabSelect }) {

    return (
        <div className={hideBackButton ? "backPage backPagePadding" : "backPage"}>
            <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item xs={haveRule ? 3 : 1}>
                    {
                        hideBackButton !== true && (
                            <BackButton backPage={backPage} />
                        )
                    }
                </Grid>
                <Grid item xs={haveRule ? 6 : 10}>
                    <div className="boxTitle">
                        {
                            namePage
                        }
                    </div>
                </Grid>
                <Grid item xs={haveRule ? 3 : 1} style={{ textAlign: "right" }}>
                    {
                        statusSearch && (
                            <SearchButton />
                        )
                    }

                    {
                        haveRule && (
                            <RuleButton setShowTabSelect={setShowTabSelect} />
                        )
                    }

                </Grid>
            </Grid>
        </div>
    );
}