// Service
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_DATA_REWARD_CODE = 'app/lottery/SET_DATA_REWARD_CODE';
const SET_DATA_REWARD_LIST = 'app/lottery/SET_DATA_REWARD_LIST';

function setDataRewardList(data) {
    return dispatch => {
        dispatch({
            type: SET_DATA_REWARD_LIST,
            payload: {
                items: data
            }
        })
    }
}

function loadRewardCode() {
    return dispatch => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        
        apiServices({}, "get", "/general/lottery/code").then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_REWARD_CODE,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_REWARD_CODE,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function loadRewardList(myJson, processing) {
    return dispatch => {
        if(processing === undefined || processing === null) {
            dispatch(setStatusSpinner({status: true, textProcess: null}));
        }

        apiServices(myJson, "post", "/general/lottery/history").then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;

                dispatch({
                    type: SET_DATA_REWARD_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_REWARD_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

export {
    loadRewardList,
    loadRewardCode,
    setDataRewardList,
    SET_DATA_REWARD_CODE,
    SET_DATA_REWARD_LIST
}