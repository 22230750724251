// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_GAME_ALL = 'app/game/SET_GAME_ALL';
const SET_GAME_DEMO = 'app/game/SET_GAME_DEMO';
const SET_SCROLL_POSITION = 'app/game/SET_SCROLL_POSITION';
const SET_SCROLL_MOVE = 'app/game/SET_SCROLL_MOVE';

function loadGameList(myJson, itemsGameList, reqStatus = true, dataGameList = [], setGameLoadStatus) {
    return dispatch => {
        const {
            camp,
            type,
            statusUseScroll
        } = myJson;
        let dataList = [];

        if (reqStatus === false) {
            setGameLoadStatus(true);
            dataList = setGameList(camp, type, statusUseScroll, dataGameList, itemsGameList);

            dispatch({
                type: SET_GAME_ALL,
                payload: {
                    items: dataList
                }
            });
        }
        else {
            apiServices(myJson, "post", `/general/list/games`).then((data) => {
                const { code: statusCode, result } = data;
                dispatch(setStatusSpinner({ status: false, textProcess: null }));

                if (statusCode === 200 || statusCode === 0 || statusCode === 23) {
                    let dataRes = result;
                    dataList = setGameList(camp, type, statusUseScroll, dataRes, itemsGameList);
                    setGameLoadStatus(true);

                    dispatch({
                        type: SET_GAME_ALL,
                        payload: {
                            items: dataList
                        }
                    });
                }
                else {
                    setGameLoadStatus(true);
                }
            });
        }
    }
}

function setGameList(camp, type, statusUseScroll, dataRes, itemsGameList) {
    let myObj = {};
    let dataList = [];
    let dataCamp = camp === "" ? "all" : camp;

    if (dataRes === null) { // เอาข้อมูลเข้าไปเก็บในตัวแปร โดยกรองจาก ค่าย และหมวดหมู่
        if (itemsGameList[type][dataCamp] === undefined) {
            dataList = [];
        }
        else {
            dataList = itemsGameList[type][dataCamp];
        }
    }
    else {
        dataList = dataRes;
    }

    if (statusUseScroll) { // เช็คสถานะการเลื่อน Scroll ว่าจะข้อมูลรีเฟรช หรือข้อมูลจาก Scroll
        let gameArrOld = (itemsGameList[type][dataCamp]) === undefined ? [] : itemsGameList[type][dataCamp];
        let dataArr = [...gameArrOld.data, ...dataList.data]; // จัดกรุ๊ปข้อมูลใหม่ และเก่า

        dataArr = dataArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        );

        myObj = { // นำข้อมูลไปแทนที่
            ...itemsGameList,
            [type]: {
                ...itemsGameList[type],
                [dataCamp]: {
                    ...itemsGameList[type][dataCamp],
                    data: dataArr
                }
            }
        };
    }
    else {
        if (itemsGameList === false || itemsGameList === null) { // เช็คว่ามีการเซ็ทข้อมูลแล้วหรือยัง
            myObj = { // นำข้อมูลไปแทนที่
                ...itemsGameList,
                [type]: {
                    [dataCamp]: dataList
                }
            };
        }
        else {
            if (itemsGameList[type] === undefined) {
                myObj = { // นำข้อมูลไปแทนที่
                    ...itemsGameList,
                    [type]: {
                        [dataCamp]: dataList
                    }
                };
            }
            else {
                if (itemsGameList[type][camp] === undefined) {
                    myObj = { // นำข้อมูลไปแทนที่
                        ...itemsGameList,
                        [type]: {
                            ...itemsGameList[type],
                            [dataCamp]: dataList
                        }
                    };
                }
                else {
                    let gameArrOld = (itemsGameList[type][dataCamp]) === undefined ? [] : itemsGameList[type][dataCamp];
                    let dataArr = [...gameArrOld.data, ...dataList.data]; // จัดกรุ๊ปข้อมูลใหม่ และเก่า

                    dataArr = dataArr.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === value.id
                        ))
                    );

                    myObj = { // นำข้อมูลไปแทนที่
                        ...itemsGameList,
                        [type]: {
                            ...itemsGameList[type],
                            [dataCamp]: {
                                ...itemsGameList[type][dataCamp],
                                data: dataArr
                            }
                        }
                    };
                }
            }
        }
    }

    return myObj;
}

function setGameDemo(data) {
    return {
        type: SET_GAME_DEMO,
        payload: {
            items: data
        }
    }
}

function setScrollStatus(data) {
    return {
        type: SET_SCROLL_POSITION,
        payload: {
            items: data
        }
    }
}

function setScrollMove(data) {
    return {
        type: SET_SCROLL_MOVE,
        payload: {
            items: data
        }
    }
}

export {
    SET_GAME_ALL,
    SET_GAME_DEMO,
    SET_SCROLL_POSITION,
    SET_SCROLL_MOVE,
    loadGameList,
    setGameDemo,
    setScrollStatus,
    setScrollMove
}