// @Utility
import { getStorage, removeStorage } from "@utility/app/storage";
import alertText from "@utility/app/alertText";

// JWT & Auth
import { setCustomerToken } from "@core/auth/jwt/jwtService";
import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";

// Redux & Action
import { loadHomeData } from "@core/redux/actions/home/index";
import { setSnackbarData } from "@core/redux/actions/app/snackbar";
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";

// Services
import { apiServices } from "@core/services/appService";

const jwtConfig = { ...jwtDefaultConfig };
const OPEN_AUTHEN = 'app/login/OPEN_AUTHEN';
// const OPEN_REGISTER = 'app/register/OPEN_REGISTER';

function openAuthenApp(data) {
    return {
        type: OPEN_AUTHEN,
        payload: {
            items: data
        }
    }
}

function authentication(myJson, close) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..." }));
        apiServices(myJson, jwtConfig.method_post, jwtConfig.loginEndpoint).then((data) => {
            let snackbarJson = {};
            const { code: statusCode, token } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                setCustomerToken(token);
                dispatch(loadHomeData());
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "เข้าสู่ระบบสำเร็จ !"
                };

                dispatch(setSnackbarData(snackbarJson));
                if(close !== undefined) {
                    close();
                }
            }
            else {
                snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function registerCustomer(data, close, selectBank, setShowAccName) {
    return dispatch => {
        if(selectBank !== null) {
            if(data.phone !== "" && data.bank_account) {
                let dataBankAccountName = "";
                let statusProcess = false;
                if(data?.bank_account_name !== undefined) {
                    if(data?.bank_account_name !== "") {
                        statusProcess = true;
                        dataBankAccountName = data?.bank_account_name;
                    }
                    else {
                        statusProcess = false;
                        dispatch(setDataAlert({
                            type: "warning",
                            title: "แจ้งเตือน",
                            text: "กรุณากรอกข้อมูลชื่อบัญชีธนาคาร !",
                            action: true,
                            redirect: "",
                            show: true,
                            buttonText: "ตกลง"
                        }));
                    }
                }
                else {
                    statusProcess = true;
                    dataBankAccountName = "";
                }

                if(statusProcess) {
                    dispatch(setStatusSpinner({status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..."}));
                    let myJson = {
                        "username": data.phone,
                        "password": "",
                        "affiliate": getStorage("affiliate") === null ? "" : getStorage("affiliate"),
                        "refer": getStorage("refer") === null ? "" : getStorage("refer"),
                        "know_web": "",
                        "allusion": getStorage("allusion"),
                        "bank_account": data.bank_account,
                        "bank_name": dataBankAccountName,
                        "bank_swift": selectBank
                    }

                    if (getStorage("market") !== null && getStorage("market") !== "Null" && getStorage("market") !== "null" && getStorage("market") !== "") {
                        myJson.refer = getStorage("market")
                    }   
                    
                    apiServices(myJson, jwtConfig.method_post, jwtConfig.registerEndpointShort).then((data) => {
                        const { code: statusCode, token, password: passwordCustomer } = data;
                        dispatch(setStatusSpinner({ status: false, textProcess: null }));
            
                        if (statusCode === 200 || statusCode === 0) {
                            setCustomerToken(token);
                            dispatch(loadHomeData());
                            removeStorage("allusion");

                            if(passwordCustomer !== null) {
                                dispatch(setDataAlert({
                                    type: "success",
                                    title: "สมัครสมาชิกสำเร็จ",
                                    text: null,
                                    action: {
                                        select: "register",
                                        username: myJson.username,
                                        password: passwordCustomer
                                    },
                                    redirect: "",
                                    show: true,
                                    buttonText: null
                                }));
                            }
                            else {
                                dispatch(setDataAlert({
                                    type: "success",
                                    title: "แจ้งเตือน",
                                    text: "สมัครสมาชิกสำเร็จ !",
                                    action: true,
                                    redirect: "",
                                    show: true,
                                    buttonText: "ตกลง"
                                }));
                            }

                            // removeStorage("refer");
                            // removeStorage("affiliate");

                            close();
                        }
                        else {
                            let dataAlt = alertText(statusCode);
                            dispatch(setDataAlert({
                                type: dataAlt.statusAlert,
                                title: dataAlt.titleAlert,
                                text: dataAlt.textAlert,
                                action: true,
                                redirect: "",
                                show: true,
                                buttonText: "ตกลง"
                            }));

                            if(statusCode === 68) {
                                setShowAccName(true);
                            }
                        }
                    });
                }
            } 
            else {
                dispatch(setDataAlert({
                    type: "warning",
                    title: "แจ้งเตือน",
                    text: "กรุณากรอกข้อมูล เบอร์โทรศัพท์ และเลขบัญชีธนาคาร !",
                    action: true,
                    redirect: "",
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        }
        else {
            dispatch(setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: "กรุณาเลือกธนาคารที่ต้องการทำรายการ !",
                action: true,
                redirect: "",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    }
}

function rememberPassword(data, switchChanel) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        apiServices({username: data.username}, jwtConfig.method_post, jwtConfig.rememberPassword).then((data) => {
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            const { code: statusCode } = data;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDataAlert({
                    type: "success",
                    title:"ขอรหัสผ่านใหม่สำเร็จ",
                    text: "ส่งรหัสผ่านใหม่ ไปยังเบอร์โทรศัพท์ของคุณเเล้ว",
                    action: true,
                    redirect: "",
                    show: true,
                    buttonText: "ตกลง"
                }));

                switchChanel('login');
            }
            else {
                let dataAlt = alertText(statusCode);
                
                dispatch(setDataAlert({
                    type: dataAlt.statusAlert,
                    title: dataAlt.titleAlert,
                    text: dataAlt.textAlert,
                    action: true,
                    redirect: "",
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function loginToken(myJson) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..." }));
        apiServices(myJson, jwtConfig.method_post, jwtConfig.loginTokenEndpoint).then((data) => {
            let snackbarJson = {};
            const { code: statusCode, token } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                setCustomerToken(token);
                dispatch(loadHomeData());
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "เข้าสู่ระบบสำเร็จ !"
                };

                dispatch(setSnackbarData(snackbarJson));
            }
        });
    }
}

export {
    OPEN_AUTHEN,
    // OPEN_REGISTER,
    authentication,
    openAuthenApp,
    registerCustomer,
    rememberPassword,
    loginToken
}