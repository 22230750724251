import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

// Auth
import { isToken, isUserLoggedIn } from "@core/auth/utils";

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";
import useParameterUrl from "@utility/hooks/useParameterUrl";

// Routes
import { BrowserRouter as Router } from 'react-router-dom';
import ViewsRoutes from "./ViewsRoutes";

// @Utility
import { setStorage, getStorage, removeStorage } from "@utility/app/storage";

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { setDataCMS } from "@core/redux/actions/app/cms";
import { loadSetting } from "@core/redux/actions/app/dataSetting";
import { loadDataCampAll } from "@core/redux/actions/camp/index";
import { openAuthenApp, loginToken } from "@core/redux/actions/auth/index";

// Component
import ErrorApp from "views/components/Error/index";
import LoadConfigPopup from "views/components/LoadConfigPopup";
// Component
// import Navbar from "views/components/Navbar";
// import NavbarSub from "views/components/NavbarSub";
// import WrapperContainer from "views/components/WrapperContainer";
// import Loadding from "views/components/Loadding";

const Layouts = ({ domain }) => {
    const dispatch = useDispatch();
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const parsed = useParameterUrl();
    const { login, register, affiliate, refer, ref, market, token } = parsed;
    const [dataSettingError, setDataSettingError] = useState(null);
    const [maintainnerStatus, setMaintainnerStatus] = useState(null);

    const setLoadDataCms = (dataCms) => {
        dispatch(setDataCMS(dataCms));
        setStorage("data_cms", JSON.stringify(dataCms));
        document.getElementsByTagName("body")[0].style.background = dataCms["bg-floor"];
        // document.getElementsByTagName("body")[0].style.background = "#D7D8DB";
        document.documentElement.style.setProperty("--bg-navbar", dataCms["bg-navbar"]); 
        document.documentElement.style.setProperty("--bg-primary", dataCms["bg-primary"]);
        document.documentElement.style.setProperty("--bg-secondary", dataCms["bg-secondary"]);
        document.documentElement.style.setProperty("--bg-confirm", dataCms["bg-confirm"]);
        document.documentElement.style.setProperty("--text-bt-confirm", dataCms["text-bt-confirm"]);
        document.documentElement.style.setProperty("--bt-login", dataCms["bt-login"]);
        document.documentElement.style.setProperty("--text-bt-login", dataCms["text-bt-login"]);
        document.documentElement.style.setProperty("--bt-register", dataCms["bt-register"]);
        document.documentElement.style.setProperty("--text-bt-register", dataCms["text-bt-register"]);
        document.documentElement.style.setProperty("--bg-brand", dataCms["bg-brand"]);
        document.documentElement.style.setProperty("--bg-label-money", dataCms["bg-label-money"]);
        document.documentElement.style.setProperty("--bg-camp", dataCms["bg-camp"]);
        document.documentElement.style.setProperty("--text-secondary", dataCms["text-secondary"]);
        document.documentElement.style.setProperty("--icon-bg-card", dataCms["icon-bg-card"]);
        document.documentElement.style.setProperty("--bg-card", dataCms["bg-card"]);
        document.documentElement.style.setProperty("--bg-select", dataCms["bg-select"]);
        document.documentElement.style.setProperty("--bg-floor", dataCms["bg-floor"]);
        document.documentElement.style.setProperty("--bg-menu-floor", dataCms["bg-menu-floor"]);
        document.documentElement.style.setProperty("--bg-label-text", dataCms["bg-label-text"]);
        document.documentElement.style.setProperty("--bt-sucess", dataCms["bt-sucess"]);
        document.documentElement.style.setProperty("--bt-danger", dataCms["bt-danger"]);
        document.documentElement.style.setProperty("--bg-warning", dataCms["bg-warning"]);
        document.documentElement.style.setProperty("--bg-blue", dataCms["bg-blue"]);
        document.documentElement.style.setProperty("--bg-orange", dataCms["bg-orange"]);
        document.documentElement.style.setProperty("--bg-black", dataCms["bg-black"]);
        document.documentElement.style.setProperty("--bg-white", dataCms["bg-white"]);
        document.documentElement.style.setProperty("--bg-gray", dataCms["bg-gray"]);
        document.documentElement.style.setProperty("--bg-text-main", dataCms["bg-text-main"]);
        document.documentElement.style.setProperty("--bg-play-game", dataCms["bg-play-game"]);
        document.documentElement.style.setProperty("--bg-popup", dataCms["bg-popup"]);
        document.documentElement.style.setProperty("--bg-floor-popup", dataCms["bg-floor-popup"]);
        document.documentElement.style.setProperty("--bg-line", dataCms["bg-line"]);

        if (dataCms["google_key"] !== "") {
            TagManager.initialize({ gtmId: dataCms["google_key"] });
        }

        let url_domain = `${window.location.protocol}//${window.location.host}`;
        let myDynamicManifest = {
            "short_name": dataCms["manifest_short_name"],
            "name": dataCms["manifest_name"],
            "icons": [
                {
                    "src": dataCms["manifest_icon_xl"],
                    "type": "image/png, image/jpg",
                    "sizes": "512x512"
                },
                {
                    "src": dataCms["manifest_icon_lg"],
                    "type": "image/png, image/jpg",
                    "sizes": "192x192"
                },
                {
                    "src": dataCms["manifest_icon_md"],
                    "type": "image/png, image/jpg",
                    "sizes": "128x128"
                },
                {
                    "src": dataCms["manifest_icon_sm"],
                    "type": "image/png, image/jpg",
                    "sizes": "96x96"
                },
                {
                    "src": dataCms["manifest_icon_xs"],
                    "type": "image/png, image/jpg",
                    "sizes": "64x64"
                }
            ],
            "scope": dataCms["manifest_scope"],
            "start_url": url_domain,
            "display": dataCms["manifest_display"],
            "theme_color": dataCms["manifest_theme_color"],
            "background_color": dataCms["manifest_background_color"]
        };

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-project").setAttribute("href", manifestURL);
        document.querySelector("#apple-touch-project").setAttribute("href", dataCms["manifest_icon_lg"]);
        document.querySelector("#icon-rel").setAttribute("href", dataCms["rel_icon_big"]);
    };

    const renderRoutes = () => {
        return (
            isToken() && (
                <Router>
                    <LoadConfigPopup dataSetting ={dataSetting } />
                    <ViewsRoutes></ViewsRoutes>
                </Router>
            )
        );
    };

    const renderContent = () => {
        if (!dataSettingError && !maintainnerStatus) {
            return renderRoutes();
        }
        else if (maintainnerStatus) {
            return <ErrorApp typeError="maintainer"></ErrorApp>;
        }
        else if(dataSettingError) {
            return <ErrorApp typeError="noData"></ErrorApp>;;
        }
        else {
            return null;
        }
    };

    useEffect(() => {
        if (dataSetting !== null) {
            if (dataSetting.cms !== null) {
                removeStorage("data_cms");
                const dataCms = dataSetting.cms;
                setLoadDataCms(dataCms);
                // setProcessingNavbar(true);
            }
            else {
                let checkCookie = getStorage("data_cms");

                if (checkCookie !== undefined) {
                    let data_cms = JSON.parse(checkCookie);
                    if (data_cms !== undefined && data_cms !== null) {
                        setLoadDataCms(data_cms);
                    }
                }
            }

            if (dataSetting.agent.agent_staus === 2 || dataSetting.agent.agent_staus === 0) {
                setMaintainnerStatus(true);
            }
            else {
                setMaintainnerStatus(false);
            }
        }
        else {

        }
    }, [dataSetting]);

    useEffect(() => {
        setStorage("allusion", document.referrer);

        if (isUserLoggedIn() === false) {
            // SHOW หน้าเข้าสู่ระบบ
            if (login === true || login === "true") {
                dispatch(openAuthenApp({ tab: "login", show: true }));
            }

            // SHOW หน้าสมัครสมาชิก
            if (register === true || register === "true") {
                dispatch(openAuthenApp({ tab: "register", show: true }));
            }
        }

        // เก็บข้อมูลเพื่อนที่เชิญ
        if (affiliate !== undefined) {
            setStorage("affiliate", affiliate)
        }

        // อ้างอิง
        if (refer !== undefined) {
            setStorage("refer", refer)
        } else if (ref !== undefined) {
            setStorage("refer", ref)
        }

        // การตลาด
        if (market !== undefined) {
            setStorage("market", market)
        }
    }, [login, register, affiliate, refer, ref, dispatch]);

    useEffectOnce(() => {
        if (isToken()) {
            dispatch(loadSetting({ "domain": domain }, setDataSettingError));
        }
    }, [isToken()]);

    useEffect(() => {
        if (dataSettingError === false) {
            dispatch(loadDataCampAll({ "type": 99 }));
        }
    }, [dataSettingError]);

    useEffectOnce(() => {
        if (isUserLoggedIn() === false) {
            if (token !== null && token !== undefined && token !== "undefined" && token !== "") {
                dispatch(loginToken({ token }));
            }
        }
    }, [isUserLoggedIn()]);

    return renderContent();
}

export default Layouts;