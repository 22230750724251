import { lazy } from 'react';

// Redux
// import { useSelector } from 'react-redux';

// Routes page
const Lotto711 = lazy(() => import("./templates/lotto711/PageRoute"));

const ViewsRoutes = () => {
    const routesRouter = () => {
        return <Lotto711 />;
    };

    return routesRouter();
};

export default ViewsRoutes;