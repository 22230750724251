// @Utility
import { getStorage } from "@utility/app/storage";

// Redux & Action
import { useSelector } from 'react-redux';

// Component
import { Grid } from '@mui/material';
import ImageShow from "views/components/ImageShow";
import WrapperContainer from "views/components/WrapperContainer";
import Contact from "views/components/Contact";
import Maintainer from "./Maintainer";
import NoData from "./NoData";

// Style & CSS
import "assets/css/Error.css";
import "assets/css/Contact.css";

const Error = ({ typeError }) => {
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);

    const gotoContact = () => {
        window.open(process.env.REACT_APP_CONTACT_DEFAULT_URL, "_blank");
    };

    const renderContact = () => {
        let dataRaw = getStorage("agent_contact");

        if (dataSetting === null && (dataRaw === undefined || dataRaw === null)) {
            return (
                <>
                    <div className="contactTitle center textBold" style={{ color: "#1F1F1F" }}>
                        ติดต่อเรา
                    </div>

                    <div className="contactTitleSub center contentMarginTop" style={{ color: "#404040" }}>
                        มีพนักงานให้บริการตลอด 24 ชม.
                    </div>

                    <div className="boxMarginTop">
                        <div className="contactMenuList positionRelative cursorPointer" style={{ background: "linear-gradient(180deg, #E7E7E7 0%, #FFFFFF 28.39%, #FFFFFF 89.06%)", border: "1px solid #C5C5C5", color: "#1F1F1F" }} onClick={() => gotoContact()}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <div className="center">
                                        <ImageShow className="contactMenuListImg" src="/images/icon/icon-line.webp" alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="contactMenuListText">
                                        ติดต่อผ่านไลน์
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </>
            );
        }
        else {
            if (dataSetting === null) {
                if (dataRaw !== undefined && dataRaw !== null) {
                    let agent_contact = JSON.parse(dataRaw);
    
                    return (
                        <Contact agent_contact={agent_contact} typeAction="error"></Contact>
                    );
                }
            }
            else {
                return (
                    <Contact agent_contact={dataSetting.agent_contact} typeAction="error"></Contact>
                );
            }
        }
    }

    const renderLogo = () => {
        if (dataSetting === null) {
            let dataRaw = getStorage("agent_logo");
            
            if (dataRaw !== undefined && dataRaw !== null) {
                let agent_logo = dataRaw;

                return (
                    <ImageShow className="imgLogoError" src={agent_logo} alt="Logo สล็อตออนไลน์" />
                );
            }
        }
        else {
            return (
                <ImageShow className="imgLogoError" src={dataSetting.agent.agent_image} alt="Logo สล็อตออนไลน์" />
            );
        }
    };

    return (
        <WrapperContainer className="contentWidth" maxWidth="sm">
            <div className="pagePadding">
                {
                    dataSetting !== null && (
                        <div className="center">
                            {
                                renderLogo()
                            }
                            <br /><br />
                        </div>
                    )
                }

                {
                    typeError === "maintainer" ?
                        <Maintainer></Maintainer>
                        :
                        <NoData></NoData>
                }

                <div className="boxMarginTop">
                    {
                        renderContact()
                    }
                </div>

                <br /><br /><br />
            </div>
        </WrapperContainer>
    );
};

export default Error;