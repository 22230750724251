import { useEffect, useRef, useState } from 'react';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Redux & Action
import { useSelector, useDispatch } from 'react-redux';
import { openAuthenApp } from "@core/redux/actions/auth/index";
import { authentication } from "@core/redux/actions/auth/index";

// JWT & Auth
import { isUserLoggedIn } from "@core/auth/utils";

// Component
import LogoApp from "./../Logo";
import ModalApp from "./../ModalFull";
import LoginApp from "./Login";
import RegisterApp from "./Register";
import RememberApp from "./Remember";
import SnackbarSet from "./../SnackbarSet";

// Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Style & CSS
import "assets/css/Input.css";

function AuthApp() {
    const childRef = useRef();
    const auth = useSelector(state => state.reduxAuth);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const nameWeb = process.env?.REACT_APP_OG_SITE_NAME;

    // เปิดใช้งาน Auth
    const onShow = (val) => {
        dispatch(openAuthenApp({ ...auth, show: val }));
    };

    // เปลีย่นช่องทาง
    const switchChanel = (val) => {
        // setData(undefined)
        dispatch(openAuthenApp({ ...auth, tab: val }));
    };

    const alertMsg = async (da) => {
        childRef.current.snackbar(da);
    }

    // ปิด modal
    const close = () => {
        setBodyScroll(false);
        onShow(false);
    }

    const authenSubmit = (data) => {
        let myJson = {
            "username": data.username,
            "password": data.password,
            "verify": ""
        };

        dispatch(authentication(myJson, close));
    };

    useEffect(() => {

        // ตรวจสอบว่ามีการเข้าสู้ระบบหรือไม่
        if (isUserLoggedIn()) {
            dispatch(openAuthenApp({
                tab: "login",
                show: false
            }));
        }

        setStep(1)
    }, [auth.show]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <ModalApp show={auth.show} onShow={onShow} step={step} onStep={setStep}>
                {
                    auth.tab === "remember" && (
                        <div className="positionRelative">
                            <div className="positionAbsolute backButtonFull" onClick={() => switchChanel("login")}>
                                <ArrowBackIcon className="iconImgFull"></ArrowBackIcon>
                            </div>
                        </div>
                    )
                }

                <div className="center">
                    <LogoApp />
                </div>

                {
                    auth.tab !== "remember" ?
                        <>
                            {auth.tab === "login" ? <LoginApp switchChanel={switchChanel} authenSubmit={authenSubmit} /> : null}
                            {auth.tab === "register" ? <RegisterApp switchChanel={switchChanel} setDataAlert={alertMsg} close={close} /> : null}
                        </>
                        :
                        <RememberApp onShow={onShow} setDataAlert={alertMsg} switchChanel={switchChanel} />
                }

                <br /><br />
                <div className="inputPaddingTop center">
                    <div style={{ fontSize: "16px" }}>
                        Copyright © 2022 supported by { nameWeb === undefined ? "" : nameWeb } <br />
                        version 1.0.0
                    </div>
                </div>

            </ModalApp>
        </>
    );
}

export default AuthApp
