import {
    SET_BASKET_DATA,
    SET_LOTTERY_OPTION
} from "@core/redux/actions/lottery/basket";

const initialState = {
    lottory: [],
    lottory_option: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_BASKET_DATA:
            return { ...state, lottory: action.payload.items };
        case SET_LOTTERY_OPTION:
            return { ...state, lottory_option: action.payload.items };
        default:
            return state;
    }
}