import { useEffect } from 'react';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Style & CSS
import "assets/css/Modal.css";

// Icon
import CloseIcon from '@mui/icons-material/Close';

export default function ModalFull({ show, onShow, children }) {
    const close = () => {
        setBodyScroll(false);
        onShow(false);
    };

    useEffect(() => {
        setBodyScroll(show);
    }, [show]);

    return (
        show && (
            <div id="modal">
                <div className="contentPosition  zIndexHight">
                    <div className="boxBodyFull">
                        <div className="boxFull">
                            <div className="positionRelative">
                                <div className="closeButtonFull" onClick={() => close(false)}>
                                    <CloseIcon className="iconImgFull"></CloseIcon>
                                </div>
                            </div>

                            <div className="boxContentFull">
                                {
                                    children
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixedBackdrop zIndexMedium" onClick={close}></div>
            </div>
        )
    );
}