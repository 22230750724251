// JWT Config
import jwtDefaultConfig from "./jwtDefaultConfig";
import { decodeToken } from 'react-jwt';

// Services
import { apiServices } from "@core/services/appService";

// @Utility
import { setStorage, getStorage, cleatStorage } from "@utility/app/storage";

const jwtConfig = { ...jwtDefaultConfig };

function getGeneralToken() {
    return getStorage("generalToken");
}

function getCustomerToken() {
    return getStorageJWT("customerToken");
}

function getStorageJWT(key) {
    try {
        return localStorage.getItem(key);
    } 
    catch(e) {
        var nameEQ = key + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return undefined;
    }
}

function setGeneralToken(value) {
    setStorage("generalToken", value);
}

function setAgentHash(value) {
    setStorage("agent", value);
}

function setCustomerToken(value) {
    setStorage("customerToken", value);
}

function checkExpireToken() {
    let customerToken = getCustomerToken();
    let tokenJson = decodeToken(customerToken);
    let expireToken = tokenJson.exp - 172800;
    let dateCurrent = Math.floor((new Date().getTime()) / 1000);
    if (dateCurrent > expireToken) {
        return true;
    }
    else {
        return false;
    }
}

function checkExpireTokenGeneral() {
    let customerToken = getGeneralToken();
    let tokenJson = decodeToken(customerToken);
    let expireToken = tokenJson.exp - 172800;
    let dateCurrent = Math.floor((new Date().getTime()) / 1000);
    if (dateCurrent > expireToken) {
        return true;
    }
    else {
        return false;
    }
}

function refreshToken() {
    apiServices({}, jwtConfig.method_get, jwtConfig.refreshToken).then((data) => {
        const { code: statusCode, token } = data;

        if (statusCode === 200 || statusCode === 0) {
            setCustomerToken(token);
        }
        else {
            destroyAllStorage();
        }
    });
}

function destroyAllStorage() {
    let market = getStorage("market");
    cleatStorage();
    setStorage("market", market, 30);
    window.location.reload(true);
}

function loadTokenGeneral(myJson, setLoadRoutes, setDataSettingError) {
    apiServices(myJson, jwtConfig.method_post, jwtConfig.generalTokenEndpoint).then((data) => {
        setLoadRoutes(true);
        const { code, token, agent_hash } = data;

        if (code === 200 || code === 0) {
            setDataSettingError(false);
            setGeneralToken(token);
            setAgentHash(agent_hash);
        }
        else {
            setDataSettingError(true);
            setGeneralToken(undefined);
        }
    });
}

export {
    getGeneralToken,
    getCustomerToken,
    setGeneralToken,
    setAgentHash,
    setCustomerToken,
    checkExpireToken,
    checkExpireTokenGeneral,
    destroyAllStorage,
    refreshToken,
    loadTokenGeneral
}