// Redux & Action
import { useSelector } from 'react-redux';

// Component
import AuthApp from "views/components/Auth/index";
import DemoApp from "views/components/Demo";
import LoadSpinner from "views/components/LoadSpinner";
import SearchGame from "views/components/SearchGame";
import ImagePopup from "views/components/ImagePopup";
import AlertApp from "views/components/Alert/index";
import SnackbarApp from "views/components/Alert/Snackbar";

export default function LoadConfigPopup({ dataSetting  }) {
    const showAuthen = useSelector(state => state.reduxAuth.show);
    const snackbarData = useSelector(state => state.reduxAppSnackbar.snackbarData);
    const alertData = useSelector(state => state.reduxAppAlert);
    const demoData = useSelector(state => state.reduxAppDemo);
    const reduxSpinner = useSelector(state => state.reduxAppSpinner.data);
    const reduxSearchGame = useSelector(state => state.reduxAppSearchGame.show);

    return (
        <>
            {
                reduxSpinner.status && (
                    <LoadSpinner textProcess={reduxSpinner.textProcess}></LoadSpinner>
                )
            }

            {
                showAuthen && (
                    <AuthApp></AuthApp>
                )
            }

            {
                <ImagePopup agentImage={dataSetting?.agent_image}></ImagePopup>
            }

            {
                reduxSearchGame && (
                    <SearchGame show={reduxSearchGame}></SearchGame>
                )
            }

            {
                snackbarData.statusShow && (
                    <SnackbarApp snackbarData={snackbarData}></SnackbarApp>
                )
            }

            {
                alertData.show && (
                    <AlertApp alertData={alertData}></AlertApp>
                )
            }

            {
                demoData.show && (
                    <DemoApp demoData={demoData}></DemoApp>
                )
            }
        </>
    );
}