const SET_BASKET_DATA = 'app/lottery/SET_BASKET_DATA';
const SET_LOTTERY_OPTION = 'app/lottery/SET_LOTTERY_OPTION';

function setBasketData(data) {
    return {
        type: SET_BASKET_DATA,
        payload: {
            items: data
        }
    }
}

function setLotteryOption(data) {
    return {
        type: SET_LOTTERY_OPTION,
        payload: {
            items: data
        }
    }
}

export {
    SET_BASKET_DATA,
    SET_LOTTERY_OPTION,
    setBasketData,
    setLotteryOption
}