const SET_STATUS_MENU_FOOTER = 'app/SET_STATUS_MENU_FOOTER';

function setStatusMenuFooter(data) {
    return {
        type: SET_STATUS_MENU_FOOTER,
        payload: {
            items: data
        }
    }
}

export {
    SET_STATUS_MENU_FOOTER,
    setStatusMenuFooter
}