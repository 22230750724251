// Redux & Action
import { useDispatch } from 'react-redux';
import { setSearchGamePopup } from "@core/redux/actions/app/searchGame";

// Icon
import Search from '@mui/icons-material/Search';

export default function SearchButton() {
    const dispatch = useDispatch();

    const gotoPage = () => {
        dispatch(setSearchGamePopup(true));
    };

    return (
        <div>
            <Search className="iconSearchPage cursorPointer positionRelative" onClick={() => gotoPage()}></Search>
        </div>
    );
}