import { SET_LOTTERY_LIST } from './../../actions/lottery/home';

const initialState = {
    list: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOTTERY_LIST:
            return { ...state, list: action.payload.items };
        default:
            return state;
    }
}