// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const LOAD_LOTTERY_INFO = "app/lottery/LOAD_LOTTERY_INFO";
const SET_LOTTERY_CODE = "app/lottery/SET_LOTTERY_CODE";

const cutDuplicateObj = (dataCode, lottoryData) => {
    let dataArrNew = lottoryData.filter((item) => item.key === dataCode);

    if(dataArrNew.length > 1) {
        let indexLast = (dataArrNew.length - 1);
        let dataArr = [dataArrNew[indexLast]];
        let dataNotDuplicate = lottoryData.filter((item) => item.key !== dataCode);

        return [...dataNotDuplicate, ...dataArr];
    }

    return lottoryData;
};

function setLotteryCode(data) {
    return dispatch => {
        dispatch({
            type: SET_LOTTERY_CODE,
            payload: {
                items: data
            }
        })
    }
}

function setLotteryInfo(data) {
    return dispatch => {
        dispatch({
            type: LOAD_LOTTERY_INFO,
            payload: {
                items: data
            }
        })
    }
}

function loadLotteryInfo(dataCode, reduxLotteryInfo, gotoPage) {
    return dispatch => {
        let myJson = {
            "lottery_code": dataCode
        };

        if(reduxLotteryInfo === null) {
            reduxLotteryInfo = [];
        }

        apiServices(myJson, "post", `/customer/lottery/info`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                let dataObj = { key: dataCode, result: result === null ? [] : result };

                dispatch({
                    type: LOAD_LOTTERY_INFO,
                    payload: {
                        items: cutDuplicateObj(dataCode, [...reduxLotteryInfo, dataObj])
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_LOTTERY_INFO,
                    payload: {
                        items: null
                    }
                });

                if(gotoPage !== undefined) {
                    gotoPage("/lottery");
                }
            }
        });
    }
}

export {
    LOAD_LOTTERY_INFO,
    SET_LOTTERY_CODE,
    setLotteryInfo,
    loadLotteryInfo,
    setLotteryCode
}