import { useState } from 'react';

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";
import useParameterUrl from "@utility/hooks/useParameterUrl";

// Auth & JWT
import { isTokenGeneral, isUserLoggedIn } from "@core/auth/utils";
import { checkExpireTokenGeneral, loadTokenGeneral } from "@core/auth/jwt/jwtService";

// Component
import Layouts from "views/Layouts";
import ConfirmWarning from "views/components/ConfirmWarning";
import ErrorApp from "views/components/Error/index";

// Style & CSS
import './App.css';
import "assets/css/Style.css";
import "assets/css/Button.css";
import "assets/css/Animation.css";

function App() {
  const [loadRoutes, setLoadRoutes] = useState(false);
  const [statusLayout, setStatusLayout] = useState(null);
  const [textData, setTextData] = useState(false);
  const [dataSettingError, setDataSettingError] = useState(null);
  const parsed = useParameterUrl();
  const { msg, agent } = parsed;
  const domain = window.location.host;
  // const domain = "nextspin711-b.711testapi.com";
  const agent_code  = process.env.REACT_APP_DEFAULT_AGENT_CODE;

  const setTokenGeneral = () => {
    let myJson = { "domain": domain };
    if(agent_code !== "" && agent_code !== undefined) {
      myJson = {
        "agent_code": agent_code,
        "agent_hash": agent_code
      };
    } 

    loadTokenGeneral(myJson, setLoadRoutes, setDataSettingError);
  };

  const renderContent = () => {
    if(statusLayout !== null) {
      if(statusLayout) {
        if(dataSettingError === false) {
          if(loadRoutes === true) {
            return (
              <Layouts loadRoutes={loadRoutes} domain={domain}></Layouts>
            );
          }
        }
        else if(dataSettingError) {
          return <ErrorApp typeError="noData"></ErrorApp>;
        }
      }
      else {
        return (
          <ConfirmWarning text={textData} close={true}></ConfirmWarning>
        );
      }
    }

    return null;
  };

  useEffectOnce(() => {
    if (msg !== null && msg !== undefined && msg !== "undefined" && msg !== "") {
      setTextData(msg);
      setStatusLayout(false);
    }
    else {
      setStatusLayout(true);

      const renderToken = () => {
        if (isUserLoggedIn()) {
          setLoadRoutes(true);
          setDataSettingError(false);
        }
        else {
          if (isTokenGeneral()) {
            if (checkExpireTokenGeneral()) {
              setTokenGeneral();
            }
            else {
              setDataSettingError(false);
              setLoadRoutes(true);
            }
          }
          else {
            setTokenGeneral();
          }
        }
      }

      renderToken();
    }
  }, []);

  return renderContent();
}

export default App;
