import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// Store
import { Provider } from 'react-redux';
import storeConfig from "@core/redux/storeConfig/store";

// @Utility
import apiDomain from "@utility/app/apiDomain";

const store = storeConfig();
const apiUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = (apiUrl === undefined || apiUrl === null || apiUrl === "") ? apiDomain(window.location.host) : apiUrl;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();