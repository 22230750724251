// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_DATA_YIKI = 'app/lottery/SET_DATA_YIKI';
const SET_YIKI_REWARD = 'app/lottery/SET_YIKI_REWARD';

const cutDuplicateObj = (dataArr) => {
    let resArr = [];
    dataArr.filter(function (item) {
        let i = resArr.findIndex(x => (x.number == item.number && x.customer_username == item.customer_username && x.time_create == item.time_create));

        if (i <= -1) {
            resArr.push(item);
        }

        return null;
    });

    return resArr;
};

function loadYikiShootList(reduxLotteryYikiList, myJson) {
    return dispatch => {
        apiServices(myJson, "post", `/customer/lottery/yiki/shoot-number/round`).then((data) => {
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;
                const getData = result === null ? [] : result;

                dispatch({
                    type: SET_DATA_YIKI,
                    payload: {
                        items: cutDuplicateObj([...getData, ...reduxLotteryYikiList])
                    }
                })
            } else {
                dispatch({
                    type: SET_DATA_YIKI,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function loadYikiShootReward(myJson) {
    return dispatch => {
        apiServices(myJson, "post", `/customer/lottery/yiki/result/round`).then((data) => {
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                const result = data.result;

                dispatch({
                    type: SET_YIKI_REWARD,
                    payload: {
                        items: result
                    }
                })
            } else {
                dispatch({
                    type: SET_YIKI_REWARD,
                    payload: {
                        items: null
                    }
                })
            }

        });
    }
}

export {
    loadYikiShootList,
    loadYikiShootReward,
    SET_DATA_YIKI,
    SET_YIKI_REWARD
}