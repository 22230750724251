import axios from 'axios';
import serviceConfig from "@core/services/serviceConfig";
import { getToken } from "@core/auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const apiServices = async (myJson, method, path, contentType) => {
  try {
    let config = {};
    let methodType = method === "file" ? "post" : method;
    let dataRequest = method === "file" ? myJson : (contentType !== null && contentType !== undefined) ? myJson : queryString.stringify(myJson);

    if(myJson === "timeout" || path === "/auth/login-general") {
      axios.defaults.timeout = 30000;
    }

    if (getToken() !== undefined) {
      config = {
        method: methodType,
        url: path,
        headers: {
          "Authorization": `${svConfig.headerType} ${getToken()}`,
          "Content-Type": (contentType !== null && contentType !== undefined) ? contentType : svConfig.contentType,
        },
        data: dataRequest
      }
    }
    else {
      config = {
        method: methodType,
        url: path,
        headers: {
          "Content-Type": (contentType !== null && contentType !== undefined) ? contentType : svConfig.contentType,
        },
        data: dataRequest
      };
    }

    const dataPromise = await axios(config).then((response) => response?.data).catch((e) => e.response?.data);
    
    if (dataPromise !== undefined) {
      if (checkStatusCode(dataPromise?.code)) {
        return dataPromise;
      }

      return {
        code: 99,
        result: null
      };
    }
    else {
      return {
        code: 99,
        result: null
      };
    }
  } catch (error) {
    console.log(error);

    return {
      code: 99,
      result: null
    };
  }
};

export {
  apiServices
};